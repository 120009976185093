@import '~bootstrap/dist/css/bootstrap.css';
@import '~font-awesome/css/font-awesome.css';

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

html * {
  font-family: 'Montserrat', sans-serif;
  color: #454a53 !important;
  font-size: 16px;
}

ngx-spinner * {
  color: #ffffff !important;
}
ngx-spinner div {
  &.loading-text,
  div.la-ball-pulse .loading-text {
    font-size: 2rem;
  }
}

p {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.23rem;
}

body a:hover {
  text-decoration: none;
}

html {
  width: 100%;
  height: 100vh;
}

body {
  margin: 0;
  height: 100%;
}

.wrap {
  background: #f1f4fb;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.questionnaire-block {
  position: relative;
  width: 100%;
  align-self: flex-start;
  max-width: 50rem;
  background: white;
  border-radius: 1rem;
  border: 1px solid #e1e2e3;
  margin: auto;
  padding: 2rem 2.5rem;
  -webkit-box-shadow: 0px 0px 70px -13px rgba(0, 0, 0, 0.69);
  -moz-box-shadow: 0px 0px 70px -13px rgba(0, 0, 0, 0.69);
  box-shadow: 0px 0px 70px -13px rgba(0, 0, 0, 0.69);
}

.fa-angle-right,
.fa-angle-left {
  color: #ffffff !important;
  font-size: 1.6rem;
  vertical-align: middle;
}

.grey-text {
  color: #b6b6b6 !important;
}

.bg-info {
  background-color: #526add !important;
}

.intro-text p:nth-child(even) {
  margin-bottom: 3rem;
}

a.gdpr-link {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 14px;
  }

  .wrap {
    padding: 0;
    background: none;
  }

  .questionnaire-block {
    flex: 1;
    border: none;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
  }
}
